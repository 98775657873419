/* src/App.css */

body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #fff;
  color: #333;
}

.App {
  text-align: center;
}

button {
  background-color: #E982A5;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #D29990;
}

@keyframes rotate {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@font-face {
  font-family: 'Berretti';
  src: url('./fonts/berretti.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}